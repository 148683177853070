@font-face {
  font-family: 'Agrandir';
  src: url('../fonts/Agrandir-Regular.otf')
  format('opentype');
}

body {
  font-family: "Agrandir", sans-serif, "Montserrat", sans-serif;
  overflow: hidden;
  margin: 0;
  font-size: 12px;
}

button {
  -webkit-tap-highlight-color: transparent;
}