@import './Animations.scss';

@keyframes bg-enter {
    from {
      width: 0px;
    }
    to {
      width: 100vw;
    }
}

@keyframes background-circle-enter {
    from {
      margin-bottom: -200vh;
    }
    to {
      margin-bottom: 0px;
    }
    0% {
      background-color: #d6683f;
    }
    50% {
      background-color: #d6683f;
    }
    100% {
      background-color: #c8afb2;
    }
}

@keyframes T-top-enter {
    from {
      width: 0px;
      left: -100vh;
    }
    to {
      width: $T-top-width;
      left: 0;
    }
}

@keyframes horizontal-line-enter {
    from {
      width: 0px;
      left: -100vh;
    }
    to {
      width: $T-top-width;
      left: 0;
    }
}

@keyframes Horizontal-line-enter-1 {
    from {
        left: 100vw;
        width: 0;
    }
    to {
        left: 0;
        width: $hori-line-1-width;
    }
}

@keyframes Horizontal-line-enter-2 {
    from {
      left: 100vw;
      width: 0;
    }
    to {
      left: 0;
      width: $hori-line-2-width;
    }
}

@keyframes Horizontal-line-enter-3 {
    from {
      left: -100vw;
      width: 0;
    }
    to {
      left: 0;
      width: $hori-line-3-width;
    }
}

@keyframes Horizontal-line-enter-4 {
    from {
      left: -100vw;
      width: 0;
    }
    to {
      left: 0;
      width: $hori-line-4-width;
    }
}

@keyframes Horizontal-line-enter-5 {
    from {
      left: -100vw;
      width: 0;
    }
    to {
      left: 0;
      width: $hori-line-5-width;
    }
}

@keyframes T-side-enter {
    from {
      height: 0;
      bottom: 100vh;
    }
    to {
      height: $T-side-height;
      bottom: 0;
    }
}

@keyframes letters-enter {
    from {
      left: 100vw;
    }
    to {
      left: 0;
    }
}

@keyframes live-ted-enter {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes live-music-soundcloud-enter {
    from {
      margin-bottom: -100vh;
    }
    to {
      margin-bottom: 0;
    }
}

@keyframes social-media-enter {
    from {
      margin-right: -100vh;
    }
    to {
      margin-right: 0;
    }
}

@keyframes buttons-enter {
    from {
      margin-left: -100vh;
    }
    to {
      margin-left: 5px;
    }
}

@keyframes loading-fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}

@keyframes found-me-fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
