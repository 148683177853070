// Large screens
@media screen and (min-width: 1100px) {
  .LettersStyle {
    font-size: 9em !important;
  }
  .live_ted_text {
    font-size: 1.75em !important;
    letter-spacing: 0.25em !important;
  }
  .react-responsive-modal-modal .modalText {
    font-size: 25px !important;
  }
}

// Medium screens
@media screen and (max-width: 1200px) {
  .live_ted_text {
    font-size: 1.75vw !important;
  }
  .modalText {
    font-size: 2vw !important;
  }
}

// Small screens
@media screen and (max-width: 660px) {
  .background-circle-wrapper {
    margin-top: -15% !important;
  }
  .LettersStyle {
    font-size: 5em !important;
    letter-spacing: 0 !important;
}
  .live_ted_text {
    font-size: 1em !important;
  }
  .buttons {
    transition: unset !important;
    width: 100% !important;
  }
  .buttons:hover {
    width: unset !important;
    background-color: unset !important;
    position: unset !important;
    margin-right: unset !important;
    transition: unset !important;
}
  .buttons:hover span {
    opacity: 0 !important;
  }
  .modalTitle {
    font-size: 1.75em !important;
  }
  .modalText {
    font-size: 3.5vw !important;
  }
  .musicButtons .MuiSvgIcon-root {
    width: 75px !important;
    height: 75px !important;
  }
  .react-responsive-modal-modal {
    min-width: 85vw !important;
    min-height: 85vw !important;
  }
  .buttons:hover span {
    position: absolute !important;
  }
}

// Extra Small Screens
@media screen and (max-width: 360px) {
  .background-circle-wrapper {
      width: 250px !important;
      height: 250px !important;
      min-width: unset !important;
      min-height: unset !important;
  }
  .LettersStyle {
      font-size: 4em !important;
  }
  .live_ted_text {
      font-size: 0.85em !important;
  }
  .out_soon {
      font-size: 1.25em !important;
  }
  .modalTitle {
  font-size: 1.5em !important;
  }
}


