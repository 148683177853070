@import './AppMediaQuery.scss';
@import './index.scss';
@import './Animations.scss';
@import './Keyframes.scss';
@import './StyleVariables.scss';

.tedious_bg {
  background-color: #6f3f59;
  background-image: url('../images/TediousBgImg.jpg');
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .appWrapper {
//   height: 100vh;
// }

.background-circle-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 50vw;
  max-width: 550px;
  max-height: 550px;
  min-width: 315px;
  min-height: 315px;
  z-index: 1;
  margin-top: -5%;
}

.background-circle {
  background-color: #c8afb2;
  width: 100% !important;
  height: 100%;
  border-radius: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.T-Top {
  background-color: white;
  padding: 1.75% 1px;
  width: $T-top-width;
  margin-left: $T-top-margin-left;
  margin-right: -5%;
  align-self: center;
  margin-top: -25%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -15%;
}

.T-Side {
  background-color: white;
  padding: 1px 2%;
  height: $T-side-height;
  position: absolute;
  bottom: 0;
  left: 8%;
}

.HorizontalLines {
  padding: 0.6% 1px;
  position: absolute;
}

.HorizontalLines:nth-child(1) {
  margin-left: $hori-line-1-margin-left;
  background-color: white;
  top: 0;
  width: $hori-line-1-width;
  margin-top: 21%;
  left: 0;
}

.HorizontalLines:nth-child(2) {
  background-color: white;
  right: 0;
  top: 0;
  left: 0;
  margin-top: 29%;
  width: $hori-line-2-width;
  margin-left: $hori-line-2-margin-left;
}

.HorizontalLines:nth-child(3) {
  background-color: white;
  width: $hori-line-3-width;
  left: 0;
  margin-top: 13%;
  margin-left: $hori-line-3-margin-left;
  top: 50%;
}

.HorizontalLines:nth-child(4) {
  background-color: white;
  width: $hori-line-4-width;
  left: 0;
  margin-top: 21%;
  margin-left: $hori-line-4-margin-left;
  top: 50%;
}

.HorizontalLines:nth-child(5) {
  margin-left: 5%;
  background-color: white;
  width: $hori-line-5-width;
  left: 0;
  margin-bottom: 20%;
  margin-top: 29%;
  top: 50%;
}

.LettersStyleWrapper {
  position: absolute;
  margin-top: 40%;
  top: 0;
  display: flex;
  justify-content: flex-start;
}

.LettersStyle {
  color: white;
  font-size: 10vw;
  font-weight: 500;
  letter-spacing: -3px;
  position: relative;
  margin-right: 3%;
  top: 0;
  left: 0;
}

.live_ted_text {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-weight: 500;
  position: absolute;
  letter-spacing: 0.5vw;
  bottom: 0;
  font-size: 1.5vw;
  margin-left: 1%;
  margin-bottom: 11%;
}

.out_soon {
  position: absolute;
  bottom: 5%;
  color:white;
  font-size: 2.5vh;
  z-index: 0;
}

.out_soon a {
  color: white;
}

.socialMediaIconsWrapper {
  right: 0;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 1;
  margin: 7px 9px 0 0;
  top: 0;
}

.buttons::before {
  font-family: "Material Icons";
  font-size: 4vh;
  color: #e5b2aa;
  display: flex;
  padding: 1vh;
  background-color: white;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  max-height: 30px;
  max-width: 30px;
}

.bio::before {
  content: 'info';
}

.listen::before {
  content: 'headphones';
}

.contact::before {
  content: 'alternate_email';
}

.loading {
  position: absolute;
  z-index: 0;
  color: white;
}

.socialMediaIconsWrapper .socialMediaIcons:hover {
  transform: scale(1.24);
  transition: transform 1s;
}

.socialMediaIcons {
  font-size: 8vh !important;
  height: 1em;
  width: 0.7em;
  transition: transform 0.3s ease-out !important;
  color: white;
  fill: white;
  padding: 5px;
}

.found_me {
  position: absolute;
  z-index: 0;
  color: white;
  top: 40vh;
}

.modalTitle {
  font-size: 2.5em;
}

.react-responsive-modal-modal .modalText {
  font-size: 2em;
  line-height: 150%;
  width: 90%;
  overflow: hidden;
}

.react-responsive-modal-modal {
  background: #c8afb2 !important;
  color: white;
  border-radius: 50%;
  width: 60vw;
  height: 60vw;
  max-width: 615px !important;
  max-height: 615px !important;
  min-width: 300px;
  min-height: 300px;
  text-align: center !important;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.react-responsive-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-responsive-modal-overlay {
  backdrop-filter: blur(6px);
}

.react-responsive-modal-closeButton {
  display: flex;
  position: static !important;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  align-content: center;
  right: 0 !important;
}

.react-responsive-modal-closeButton svg {
  fill: white;
}

.musicButtons {
  background: unset;
  border: unset;
}

.musicButtons .MuiSvgIcon-root {
  width: 100px;
  height: 100px;
  color: white;
}

.contact {
  z-index: 2;
}

.listen {
  z-index: 1;
}

.buttonsIconsWrapper {
  display: flex;
  position: relative;
  z-index: 0;
  margin: unset;
  top: 0;
}

.socialMediaIconsWrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
  margin-left: -20%;
  margin: unset;
  top: 0;
}

.buttons {
  width: 60px;
  position: relative;
  color: white;
  margin-left: 5px;
  display: flex;
  border-color: transparent;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50vw;
  padding: 0 5px 0 0;
  transition: all 0.2s ease-in;
}

.buttons:hover {
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease-in;
  position: relative;
  width: -moz-fit-content;
  width: 150px;
}

.buttons span {
  opacity: 0;
  position: absolute;
  font-size: 1.5em;
  user-select: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  height: fit-content;
}

.buttons:hover span {
  color: #e5b2aa;
  opacity: 1;
  position: relative;
  transition: opacity 0.2s ease-in;
}