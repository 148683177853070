// T-Side
$T-side-height: 63%;

// T-Top
$T-top-margin-left: -4%;
$T-top-width: 108%;

// HorizontalLines
$hori-line-1-margin-left: 27%;
$hori-line-1-width: 68%;
$hori-line-2-margin-left: 18%;
$hori-line-2-width: 83%;
$hori-line-3-margin-left: -4%;
$hori-line-3-width: 108%;
$hori-line-4-margin-left: 0%;
$hori-line-4-width: 83%;
$hori-line-5-margin-left: 4%;
$hori-line-5-width: 69%;